<template>
    <div>
        <div class="row label-color mb-3">
            <div v-show="hasCompanySelectAuth(roleId)" class="col-sm-6 col-md-4 col-lg-3">
                <label>会社名</label>
                <CompanyList
                    ref="companyList"
                    elm-id="select-company"
                    :store-id="storeId"
                    :include-all="true"
                    :show-with-code="hasCompanySelectAuth(roleId)"
                    @data-loaded="handleDataLoaded"
                    label-all="全て"
                ></CompanyList>
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
                <label for="select-location">拠点名</label>
                <LocationList
                    ref="locationList"
                    elm-id="select-location"
                    :store-id="storeId"
                    :include-all="true"
                    @data-loaded="handleDataLoaded"
                    label-all="全て"
                ></LocationList>
            </div>

            <div class="col-md-3 ms-auto text-end">
                <button
                    :disabled="isLoading"
                    id="btn_save"
                    type="button"
                    class="btn btn-add-new icon-button ms-auto"
                    data-bs-toggle="modal"
                    data-bs-target="#driverModal"
                    @click="onClickRegister"
                >
                    <img src="/images/add.svg" width="20px" />
                    <span>運転者の新規追加</span>
                </button>
                <a
                    href="#"
                    v-if="!isMaintenanceMode"
                    :disabled="isLoading"
                    @click="onClickShowDeleted"
                    id="btn_showdlist"
                    class="link-list"
                >
                    <img src="/images/arrow_drop_right.svg"/>
                    削除済み運転者一覧
                </a>
                <a
                    href="#"
                    v-if="isMaintenanceMode"
                    :disabled="isLoading"
                    @click="onClickBackToValidDrivers"
                    id="btn_back"
                    class="link-list"
                >
                    <img src="/images/arrow_drop_right.svg"/>
                    有効運転者一覧に戻る
                </a>
            </div>
        </div>

        <div class="row mb-3">
            <div class="col-sm-6 col-md-4 col-lg-3">
                <label for="select-rep_name">氏名</label>
                <input
                    v-model="name"
                    @keypress.enter.prevent
                    id="select-rep_name"
                    autocomplete="false"
                    type="text"
                    class="form-control mdb-autocomplete"
                    name="select-name"
                    placeholder=""
                    list="autocompelete-box"
                >
                <div id="autocompelete-box" class="col-md-12 active-border" hidden></div>
                <input type="hidden" id="hd-position-relative" name="hd-position-relative">
                <datalist id="select-rep_name_d"/>
            </div>

            <div class="col-sm-6 col-md-4 col-lg-3">
                <label for="select-activate_flag">測定対象</label>
                <div class="col-md-12" style="display: flex; gap: 10px;">
                    <button
                        type="button"
                        :class="[
                            'btn',
                            'filter-link',
                            status.targeted ? 'filter-active' : '',
                        ]"
                        data-id="1"
                        @click="toggleStatus('targeted')"
                    >
                        対象者
                    </button>
                    <button
                        type="button"
                        :class="[
                            'btn',
                            'filter-link',
                            status.excluded ? 'filter-active' : '',
                        ]"
                        data-id="0"
                        @click="toggleStatus('excluded')"
                    >
                        対象外
                    </button>
                </div>
            </div>
        </div>

        <div class="row btn-spacer"></div>

        <div class="row">
            <div class="col"></div>
            <div class="col-12 col-md-6 d-flex justify-content-center">
	        <button
                    class="btn mx-1 btn-reset flex-fill"
                    @click="onClickClearSearchParams"
                >
		    検索条件をリセット
	        </button>
	        <button
                    :disabled="isLoading"
                    class="btn mx-1 btn-search flex-fill"
                    @click="onClickSearch"
                >
                    <img src="/images/search.svg" width="20px" />
                    この条件で検索
	        </button>
            </div>
            <div class="col"></div>
        </div>
        <div v-if="!isMaintenanceMode" class="row">
            <div class="col ms-auto text-end">
		<button
                    :disabled="isLoading"
                    @click="onClickUpload"
                    type="button"
                    class="btn btn-light link-style-button"
                    id="import_driver_csv"
                    style="cursor:pointer;"
                >
		    <img src="/images/upload.svg" class="calendar_div mx-1" width="20" height="20" />
		    <span class="select_notconfirm">運転者の一括登録</span>
		</button>
		<button
                    :disabled="isLoading"
                    @click="onClickDownload"
                    type="button"
                    class="btn btn-light link-style-button"
                    id="export_driver_xlsx"
                    style="cursor: pointer"
                >
		    <img src="/images/download.svg" class="calendar_div mx-1" width="20" height="20" />
		    <span class="download_data">ダウンロード</span>
		</button>
            </div>
        </div>
        <div v-else class="row del-driver-spacer"></div>

        <div v-if="hasPortableLicense" class="row mb-3 mb-3 text-jp">
            ※運転者管理で追加したユーザーはALPiTのアプリで認証する際に使います。据置型管理の運転者の登録は出来兼ねます。
        </div>
    </div>
</template>

<script>
 import CompanyList from '@/components/CompanyList.vue';
import LocationList from '@/components/LocationList.vue';
import axios from '@/composables/axios.js';
import { hasCompanySelectAuth } from '@/composables/common.js';
import { useCommonStore } from '@/stores/common';
import { createCompanyLocationParamState } from '@/stores/companylocation';

 export default {
     setup() {
         return {
             hasCompanySelectAuth,
         };
     },
     components: {
         CompanyList,
         LocationList,
     },
     props: {
         roleId: {type: Number, required: true},
     },
     computed: {
         // XXX mapper
         isMaintenanceMode() {
             return this.st.driver.isMaintenanceMode;
         },
         hasPortableLicense() {
             // XXX 既存のコードでの判定方法。
             return this.licenseTypeCount >= 2;
         },
         isLoading() {
             if (this.params.isLoading) return true;
             return false;
         },
     },
     data: function() {
         return {
             storeId: 'search-list',
             params: createCompanyLocationParamState('search-list'),
             st: useCommonStore(),
             licenseTypeCount: 0,
             name: '',
             status: {
                 targeted: true,
                 excluded: true,
             },
             initialized: false,
             listComponentStates: {company: true, location: true},
         };
     },
     mounted: async function() {
         await this.updateLicenseTypeCount();
     },
     methods: {
         async updateLicenseTypeCount() {
             const response = await axios.get('/license/typecount');
             this.licenseTypeCount = response.data.count*1;
         },
         async onClickSearch() {
             driver_search();
         },
         async onClickShowDeleted() {
             screenType = "restore"; // global
             const div = document.getElementById('error_message');
             div.className = '';
             document.getElementById('error_message').innerHTML = "";
             $('#btn_save').hide();
             this.st.driver.isMaintenanceMode = true;
             $('.page-name').text("運転者管理　削除済み運転者");
             await driver_search();
         },
         async onClickBackToValidDrivers() {
             screenType = "normal"; // global
             $('#btn_save').show();
             this.st.driver.isMaintenanceMode = false;
             $('.page-name').text("運転者管理");
             await driver_search();
         },
         async onClickRegister() {
             driverRegistration();
         },
         onClickUpload() {
             DriverImportFormShow();
         },
         onClickDownload() {
             export_driver_xlsx();
         },
         onClickClearSearchParams(event) {
             event.preventDefault();
             this.$refs.companyList.clearSelection();
             this.$refs.locationList.clearSelection();
             this.name = '';
             this.status = {
                 targeted: true,
                 excluded: true,
             };
         },
         toggleStatus(value) {
             if (value == "targeted") {
                 this.status.targeted ? this.status.targeted=false : this.status.targeted=true;
             } else if (value == "excluded"){
                 this.status.excluded ? this.status.excluded=false : this.status.excluded=true;
             }
         },
         handleDataLoaded(data) {
             for (const [key, value] of Object.entries(data)) {
                 this.listComponentStates[key] = value;
             }
             if (this.initialized) return;
             const allCompleted = Object.values(this.listComponentStates)
                                        .every(value => value === true);
             if (allCompleted) {
                 driver_search();
                 this.initialized = true;
             }
         },
     },
     watch: {
     },
 }
</script>
