<template>
    <div>
        <Modal
            v-model="showModal"
            modal-class="new-modal"
            wrapper-class="new-modal-wrapper"
            :enableClose="false"
            :title="$_cstr('STRID_INSTRUCTION_MODAL_TITLE')"
        >
            <form @submit.prevent="execute">
                <div class="row">
                    <div class="col">
                        <label class="form-label required">
                            {{ $_cstr('STRID_INSTRUCTION_LABEL_OK') }}
                        </label>
                        <input v-model="messageOk" class="form-control" type="text" :disabled="isDisabled">
                        <span v-if="errors.ok" class="validation-error">{{ errors.ok[0] }}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <label class="form-label required">
                            {{ $_cstr('STRID_INSTRUCTION_LABEL_NG') }}
                        </label>
                        <input v-model="messageNg" class="form-control" type="text" :disabled="isDisabled">
                        <span v-if="errors.ng" class="validation-error">{{ errors.ng[0] }}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <label class="form-label required">
                            {{ $_cstr('STRID_INSTRUCTION_LABEL_NC') }}
                        </label>
                        <input v-model="messageNc" class="form-control" type="text" :disabled="isDisabled">
                        <span v-if="errors.nc" class="validation-error">{{ errors.nc[0] }}</span>
                    </div>
                </div>

                <div class="row">
                    <div class="col">
                        <label class="form-label">
                            {{ $_cstr('STRID_INSTRUCTION_LABEL_OPT_1') }}
                        </label>
                        <input v-model="messageOpt1" class="form-control" type="text" :disabled="isDisabled">
                        <span v-if="errors.opt1" class="validation-error">{{ errors.opt1[0] }}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <label class="form-label">
                            {{ $_cstr('STRID_INSTRUCTION_LABEL_OPT_2') }}
                        </label>
                        <input v-model="messageOpt2" class="form-control" type="text" :disabled="isDisabled">
                        <span v-if="errors.opt2" class="validation-error">{{ errors.opt2[0] }}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <label class="form-label">
                            {{ $_cstr('STRID_INSTRUCTION_LABEL_OPT_3') }}
                        </label>
                        <input v-model="messageOpt3" class="form-control" type="text" :disabled="isDisabled">
                        <span v-if="errors.opt3" class="validation-error">{{ errors.opt3[0] }}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <label class="form-label">
                            {{ $_cstr('STRID_INSTRUCTION_LABEL_OPT_4') }}
                        </label>
                        <input v-model="messageOpt4" class="form-control" type="text" :disabled="isDisabled">
                        <span v-if="errors.opt4" class="validation-error">{{ errors.opt4[0] }}</span>
                    </div>
                </div>

                <div class="row modal-footer mt-2">
                    <div class="col ms-auto d-flex justify-content-end">
                        <div class="mx-2">
                            <button class="btn btn-info" type="button" :disabled="isDisabled" @click="showModal = false">
                                {{ $_cstr('STRID_BUTTON_LABEL_CANCEL') }}
                            </button>
                        </div>
                        <div class="mx-2">
                            <button :class="['btn', {'btn-primary': !isDeleteMode, 'btn-danger': isDeleteMode}]"
                                type="submit" :disabled="isDisabled" @click="">
                                {{ $_cstr('STRID_BUTTON_LABEL_SAVE') }}
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </Modal>
    </div>
</template>
<script>
 import axios from '@/composables/axios.js';
 import { isStringEmpty, checkAlpitResponse } from '@/composables/common.js';
 export default {
     props: {
     },
     computed: {
         isDisabled() {
             return this.isLoading;
         },
     },
     data: function() {
         return {
             showModal: false,
             isLoading: false,
             messageOk: null,
             messageNg: null,
             messageNc: null,
             messageOpt1: null,
             messageOpt2: null,
             messageOpt3: null,
             messageOpt4: null,
             errors:[],
         };
     },
     mounted: async function() {
         this.$root.$instructionModal = this;
         this.initialize();
     },
     methods: {
         initialize() {
             this.messageOk = null;
             this.messageNg = null;
             this.messageNc = null;
             this.messageOpt1 = null;
             this.messageOpt2 = null;
             this.messageOpt3 = null;
             this.messageOpt4 = null;
             this.errors = [];
         },
         async open() {
             this.initialize();
             this.showModal = true;
             this.isLoading = true;
             const response = await axios.get('/instruction');
             this.isLoading = false
             if (!checkAlpitResponse(response)) {
                 console.error('response is invalid');
                 console.error(response);
                 this.showModal = false;
                 return;
             }
             this.setFields(response);
         },
         async execute() {
             this.errors = [];
             const postData = {
                 'ok': this.messageOk,
                 'ng': this.messageNg,
                 'nc': this.messageNc,
                 'opt1': this.messageOpt1,
                 'opt2': this.messageOpt2,
                 'opt3': this.messageOpt3,
                 'opt4': this.messageOpt4,
             };
             try {
                 this.isLoading = true;
                 const response = await axios.post('/instruction/save', postData);
                 this.showModal = false;
             } catch (error) {
                 console.error(error);
                 this.errors = error?.response?.data?.errors;
             } finally {
                 this.isLoading = false;
             }
         },
         setFields(resp) {
             const data = resp.data.data;
             this.messageOk = data?.message_ok;
             this.messageNg = data?.message_ng;
             this.messageNc = data?.message_nc;

             const optInf = data.optional_instructions;
             if (optInf) {
                 this.messageOpt1 = optInf.filter(o => o.display_number == 1)[0]?.message;
                 this.messageOpt2 = optInf.filter(o => o.display_number == 2)[0]?.message;
                 this.messageOpt3 = optInf.filter(o => o.display_number == 3)[0]?.message;
                 this.messageOpt4 = optInf.filter(o => o.display_number == 4)[0]?.message;
             }
         },
     },
 }
</script>
