<template>
    <Modal
        v-model="showModal"
        modal-class="new-modal notification-modal confirm-no-title"
        wrapper-class="new-modal-wrapper"
        :enableClose="false"
    >
        <form @submit.prevent="execute">
            <div class="new-modal-content">
                <div v-if="message" class="alert message-area mb-3">
                    {{ message }}
                </div>
                <div class="mb-3">
                    <dl class="instruction-confirm-list">
                        <div class="list-item">
                            <dt>
                                <span>
                                    {{ $_cstr('STRID_INSTRUCTION_LABEL_CONFIRMER') }}
                                </span>
                            </dt>
                            <dd>
                                {{ confirmerInfo.name }}
                            </dd>
                        </div>
                        <div class="list-item">
                            <dt>
                                <span>
                                    {{ $_cstr('STRID_INSTRUCTION_LABEL_INSTRUCTION_DETAIL') }}
                                </span>
                            </dt>
                            <dd>
                                <select v-model="selectedItem" class="form-select">
                                    <option v-for="item in items" :key="item.id" :value="item.id">
                                        {{ item.instruction }}
                                    </option>
                                </select>
                            </dd>
                        </div>
                    </dl>
                </div>
            </div>

            <div class="modal-footer mt-2">
                <div class="ms-auto d-flex justify-content-end button-area">
                    <div>
                        <button class="btn btn-info" type="button" :disabled="isDisabled" @click="onClose">
                            {{ $_cstr('STRID_BUTTON_LABEL_CANCEL') }}
                        </button>
                    </div>
                    <div>
                        <button class="btn btn-primary" type="submit" :disabled="isDisabled">
                            {{ $_cstr('STRID_BUTTON_LABEL_REGISTER') }}
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </Modal>
</template>

<script>
 import axios from '@/composables/axios.js';
 import { isStringEmpty, isListEmpty, checkAlpitResponse } from '@/composables/common.js';
 import BaseToastComponent from '@/components/common/BaseToastComponent.vue';
 export default {
     mixins: [
         BaseToastComponent,
     ],
     props: {
         confirmerInfo: {type: Object, required: true},
     },
     computed: {
         isDisabled() {
             return this.isLoading;
         },
     },
     data: function() {
         return {
             message: null,
             showModal: false,
             isLoading: false,
             items: [],
             selectedItem: null,
             checkedInfo: [],
             checkedElms: [],
         };
     },
     mounted: async function() {
         this.$root.$confirmInstruction = this;
         this.initialize();
     },
     methods: {
         initialize() {
             this.message = null;
             this.items = [];
             this.selectedItem = null;
             this.checkedInfo = [];
             this.checkedElms = [];
         },
         async updateList() {
             this.isLoading = true;
             const response = await axios.get('/instructions');
             this.isLoading = false
             if (!checkAlpitResponse(response)) {
                 console.error(response);
                 return false;
             }
             this.items = response.data.data;
             this.selectedItem = this.items[0].id;

             return true;
         },
         async open(info,mode = 'single') {
             this.initialize();

             const strid = mode == 'single' ? 'STRID_CONFIRM_INSTRUCTION_MESSAGE_SINGLE':
                           mode == 'multi' ? 'STRID_CONFIRM_INSTRUCTION_MESSAGE_MULTI':
                           'STRID_CONFIRM_INSTRUCTION_MESSAGE_SINGLE';
             this.message = this.$_cstr(strid);

             this.checkedInfo = info;
             const ids = this.checkedInfo.map(i => i.id);
             const checkboxes = $.find('input[type="checkbox"]');
             this.checkedElms = $(checkboxes).filter((idx,c) => ids.includes($(c).val()));

             const result = await this.updateList();
             if (result) this.showModal = true;
         },
         onClose() {
             this.checkedElms.prop('checked', false);
             this.showModal = false;
         },
         async execute() {
             const postData = {
                 ids: this.checkedInfo,
                 instructionId: this.selectedItem,
             };
             try {
                 const response = await axios.post('/result/approveinstruction', postData);
                 //this.toastMessage('info', '設定しました',true,3000);
                 reloadResultDataTable();
                 this.showModal = false;
             } catch (error) {
                 console.error(error);
                 const statusCode = error?.response?.status;
                 if (statusCode == 422) {
                     this.toastMessage('error', 'エラーが発生しました', true);
                 } else {
                     const errorMessage = error?.response?.data?.message;
                     if (!isStringEmpty(errorMessage)) this.toastMessage('error', errorMessage, true);
                 }
             }
         },
     },
 }
</script>
