<template>
    <div>
        <Modal
            v-model="showModal"
            modal-class="new-modal notification-modal"
            wrapper-class="new-modal-wrapper"
            :enableClose="false"
            :title="$_cstr('STRID_CONFIRM_INSTRUCTION_TITLE')"
        >
            <form @submit.prevent="execute">
                <div class="new-modal-content">
                    <div class="mb-3">
                        <dl class="instruction-confirm-list">
                            <div class="list-item">
                                <dt>
                                    {{ $_cstr('STRID_INSTRUCTION_LABEL_CONFIRMER') }}
                                </dt>
                                <dd>
                                    {{ confirmer.name }}
                                </dd>
                            </div>
                            <div class="list-item">
                                <dt>
                                    {{ $_cstr('STRID_INSTRUCTION_LABEL_INSTRUCTION_DETAIL') }}
                                </dt>
                                <dd>
                                    <select v-model="selectedItem" class="form-select">
                                        <option v-for="item in items" :key="item.id" :value="item.id">
                                            {{ item.instruction }}
                                        </option>
                                    </select>
                                </dd>
                            </div>
                        </dl>
                    </div>
                </div>

                <div class="modal-footer mt-2">
                    <div class="ms-auto d-flex justify-content-end">
                        <div class="mx-2">
                            <button class="btn btn-info" type="button" :disabled="isDisabled" @click="onClose">
                                {{ $_cstr('STRID_BUTTON_LABEL_CANCEL') }}
                            </button>
                        </div>
                        <div class="mx-2">
                            <button class="btn btn-primary" type="submit" :disabled="isDisabled">
                                {{ $_cstr('STRID_BUTTON_LABEL_REGISTER') }}
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </Modal>
    </div>
</template>

<script>
 import axios from '@/composables/axios.js';
 import { isStringEmpty, isListEmpty, checkAlpitResponse } from '@/composables/common.js';
 export default {
     props: {
         confirmerInfo: {type: Object, required: true},
     },
     computed: {
         isDisabled() {
             return this.isLoading;
         },
     },
     data: function() {
         return {
             showModal: false,
             isLoading: false,
             confirmer: null,
             items: [],
             selectedItem: null,
             checkedIds: [],
             checkedElms: [],
         };
     },
     mounted: async function() {
         this.$root.$confirmInstruction = this;
         this.initialize();
     },
     methods: {
         initialize() {
             this.confirmer = JSON.parse(this.confirmerInfo);
             this.items = [];
             this.selectedItem = null;
             this.checkedIds = [];
             this.checkedElms = [];
         },
         async updateList() {
             this.isLoading = true;
             const response = await axios.get('/instructions');
             this.isLoading = false
             if (!checkAlpitResponse(response)) {
                 console.error(response);
                 return false;
             }
             this.items = response.data.data;
             this.selectedItem = this.items[0].id;

             return true;
         },
         async open(ids) {
             this.initialize();

             this.checkedIds = ids;
             const checkboxes = $.find('input[type="checkbox"]');
             this.checkedElms = $(checkboxes).filter((idx,c) => ids.includes($(c).val()));

             const result = await this.updateList();
             if (result) this.showModal = true;
         },
         onClose() {
             this.checkedElms.prop('checked', false);
             this.showModal = false;
         },
         async execute() {
             const postData = {
                 ids: this.checkedIds,
                 instructionId: this.selectedItem,
             };
             try {
                 const response = await axios.post('/result/approveinstruction', postData);
                 //this.toastMessage('info', '送信しました。',3000);
                 reloadResultDataTable();
                 this.showModal = false;
             } catch (error) {
                 //const validationErrors = error?.response?.data?.errors;
                 if (validationErrors) {
                     //this.toastValidateMessage(validationErrors);
                 } else {
                     console.error(error);
                 }
             }
         },
     },
 }
</script>
