<template>
    <div>
        <Modal
            v-model="showModal"
            modal-class="new-modal"
            wrapper-class="new-modal-wrapper"
            :enableClose="false"
            :title="$_cstr('STRID_INSTRUCTION_MODAL_TITLE')"
        >
            <form @submit.prevent="execute">
                <div class="new-modal-content">
                    <div class="row">
                        <div class="col">
                            <label class="form-label required">
                                {{ $_cstr('STRID_INSTRUCTION_LABEL_OK') }}
                            </label>
                            <input v-model="messageOk" class="form-control" type="text" :maxlength="maxLength" :disabled="isDisabled">
                            <span v-if="validationErrorMessage(0)" class="validation-error">{{ validationErrorMessage(0) }}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <label class="form-label required">
                                {{ $_cstr('STRID_INSTRUCTION_LABEL_NG') }}
                            </label>
                            <input v-model="messageNg" class="form-control" type="text" :maxlength="maxLength" :disabled="isDisabled">
                            <span v-if="validationErrorMessage(1)" class="validation-error">{{ validationErrorMessage(1) }}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <label class="form-label required">
                                {{ $_cstr('STRID_INSTRUCTION_LABEL_NC') }}
                            </label>
                            <input v-model="messageNc" class="form-control" type="text" :maxlength="maxLength" :disabled="isDisabled">
                            <span v-if="validationErrorMessage(2)" class="validation-error">{{ validationErrorMessage(2) }}</span>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col">
                            <label class="form-label">
                                {{ $_cstr('STRID_INSTRUCTION_LABEL_OPT_1') }}
                            </label>
                            <input v-model="opt1.message" class="form-control" type="text" :maxlength="maxLength" :disabled="isDisabled">
                            <span v-if="validationErrorMessage(3)" class="validation-error">{{ validationErrorMessage(3) }}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <label class="form-label">
                                {{ $_cstr('STRID_INSTRUCTION_LABEL_OPT_2') }}
                            </label>
                            <input v-model="opt2.message" class="form-control" type="text" :maxlength="maxLength" :disabled="isDisabled">
                            <span v-if="validationErrorMessage(4)" class="validation-error">{{ validationErrorMessage(4) }}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <label class="form-label">
                                {{ $_cstr('STRID_INSTRUCTION_LABEL_OPT_3') }}
                            </label>
                            <input v-model="opt3.message" class="form-control" type="text" :maxlength="maxLength" :disabled="isDisabled">
                            <span v-if="validationErrorMessage(5)" class="validation-error">{{ validationErrorMessage(5) }}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <label class="form-label">
                                {{ $_cstr('STRID_INSTRUCTION_LABEL_OPT_4') }}
                            </label>
                            <input v-model="opt4.message" class="form-control" type="text" :maxlength="maxLength" :disabled="isDisabled">
                            <span v-if="validationErrorMessage(6)" class="validation-error">{{ validationErrorMessage(6) }}</span>
                        </div>
                    </div>
                </div>
                <div class="row modal-footer mt-2">
                    <div class="col ms-auto d-flex justify-content-end button-area">
                        <div>
                            <button class="btn btn-info" type="button" :disabled="isDisabled" @click="onClose">
                                {{ $_cstr('STRID_BUTTON_LABEL_CANCEL') }}
                            </button>
                        </div>
                        <div>
                            <button :class="['btn', 'btn-primary']"
                                type="submit" :disabled="isDisabled" @click="">
                                {{ $_cstr('STRID_BUTTON_LABEL_SAVE') }}
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </Modal>
    </div>
</template>
<script>
 import axios from '@/composables/axios.js';
 import { isStringEmpty, checkAlpitResponse } from '@/composables/common.js';
 import BaseToastComponent from '@/components/common/BaseToastComponent.vue';
 export default {
     mixins: [
         BaseToastComponent,
     ],
     props: {
         maxLength: {type: Number, default: 20},
     },
     computed: {
         isDisabled() {
             return this.isLoading;
         },
     },
     data: function() {
         return {
             showModal: false,
             isLoading: false,
             instruction: null,
             messageOk: null,
             messageNg: null,
             messageNc: null,
             opt1: {},
             opt2: {},
             opt3: {},
             opt4: {},
             errors:[],
         };
     },
     mounted: async function() {
         this.$root.$instructionModal = this;
         this.initialize();
     },
     methods: {
         initialize() {
             this.instruction = null;
             this.messageOk = null;
             this.messageNg = null;
             this.messageNc = null;
             this.opt1 = {};
             this.opt2 = {};
             this.opt3 = {};
             this.opt4 = {};
             this.errors = [];
         },
         async open() {
             this.initialize();
             this.showModal = true;
             this.isLoading = true;
             const response = await axios.get('/instruction');
             this.isLoading = false
             if (!checkAlpitResponse(response)) {
                 console.error('response is invalid');
                 console.error(response);
                 this.showModal = false;
                 return;
             }
             this.setFields(response);
         },
         onClose() {
             this.closeToast();
             this.showModal = false;
         },
         async execute() {
             this.errors = [];
             const postData = [
                 {id: 'ok', message: this.messageOk, updated_at: this.instruction.updated_at},
                 {id: 'ng', message: this.messageNg, updated_at: this.instruction.updated_at},
                 {id: 'nc', message: this.messageNc, updated_at: this.instruction.updated_at},
                 {id: 'opt1', message: this.opt1.message, updated_at: this.opt1.updated_at},
                 {id: 'opt2', message: this.opt2.message, updated_at: this.opt2.updated_at},
                 {id: 'opt3', message: this.opt3.message, updated_at: this.opt3.updated_at},
                 {id: 'opt4', message: this.opt4.message, updated_at: this.opt4.updated_at},
             ];
             try {
                 this.isLoading = true;
                 const response = await axios.post('/instruction/save', postData);
                 this.showModal = false;
             } catch (error) {
                 console.error(error);
                 const statusCode = error?.response?.status;
                 if (statusCode == 422) {
                     const errors = error?.response?.data?.errors;
                     if (errors) this.applyErrorData(errors);
                 } else {
                     const errorMessage = error?.response?.data?.message;
                     if (!isStringEmpty(errorMessage)) this.toastMessage('error', errorMessage, true);
                 }
             } finally {
                 this.isLoading = false;
             }
         },
         setFields(resp) {
             this.instruction = resp.data.data;
             this.messageOk = this.instruction?.message_ok;
             this.messageNg = this.instruction?.message_ng;
             this.messageNc = this.instruction?.message_nc;

             const optInf = this.instruction.optional_instructions;
             if (optInf) {
                 this.opt1 = optInf.filter(o => o.display_number == 1)[0];
                 this.opt2 = optInf.filter(o => o.display_number == 2)[0];
                 this.opt3 = optInf.filter(o => o.display_number == 3)[0];
                 this.opt4 = optInf.filter(o => o.display_number == 4)[0];
             }
         },
         applyErrorData(errors) {
             let result = [];
             for (let key in errors) {
                 const [index, field] = key.split(".");
                 const value = errors[key][0];

                 if (!result[index]) result[index] = {};
                 result[index][field] = value;
             }
             this.errors = result;
         },
         validationErrorMessage(idx) {
             const targetError = this.errors[idx];
             if (isStringEmpty(targetError)) return null;
             return Object.values(targetError)[0];
         },
     },
 }
</script>
