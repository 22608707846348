<template />
<script>
 import {useToast} from 'vue-toast-notification';
 import 'vue-toast-notification/dist/theme-sugar.css';
 export default {
     props: {
     },
     data: function() {
         return {
             _toast: null,
             _toastParams: {
                 position: 'bottom',
                 duration: 0,
                 pauseOnHover: true,
             },
         };
     },
     mounted() {
         this._toast = useToast();
     },
     methods: {
         closeToast() {
             if (this._toast && this._toast.clear) this._toast.clear();
         },
         _toastMessage(toastType,msg,stackable=true,duration=0) {
             if (!stackable) this.closeToast();
             const params = {
                 type: toastType,
                 message: msg,
                 position: this._toastParams.position,
                 duration: this._toastParams.duration,
                 pauseOnHover: this._toastParams.pauseOnHover,
             };
             if (duration > 0) {
                 params.duration = duration;
             }
             this._toast.open(params);
         },
         _toastMessageWithParams(args) {
             const params = {...this._toastParams,...args};
             this._toast.open(params);
         },
         toastMessage(...args) {
             if (args && args.length === 1 && typeof args[0] === 'object') {
                 return this._toastMessageWithParams(args);
             }
             return this._toastMessage(args[0],args[1],args[2],args[3]);
         },
     },
 }
</script>
