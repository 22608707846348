export function hasRespError(composableApi){
    if (composableApi.error) return true;
    if (composableApi.response.status == 200) return false;
    console.error(composableApi);
    return true;
}
export function isStringEmpty(str) {
    if (str == undefined) return true;
    if (str == '') return true;
    return false;
}
export function isListEmpty(list) {
    if (list == null) return true;
    if (list.length == null) return true;
    if (!Number.isInteger(list.length)) return true;
    if (list.length < 1) return true;
    return false;
}
export function addKeyValueToCookie(cookieName, key, value, days) {
    var cookieValue = getCookie(cookieName);
    var cookieObj = {};
    
    if (cookieValue) {
	cookieObj = JSON.parse(cookieValue);
    }
    
    cookieObj[key] = value;
    
    var newCookieValue = JSON.stringify(cookieObj);
    setCookie(cookieName, newCookieValue, days);
}

export function setCookie(name, value, days) {
    var expires = "";
    if (days) {
	var date = new Date();
	date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        // 2024/05/22 : アイリス指示：Cookie設定期間24時間→180秒に変更
	//date.setTime(date.getTime() + (180 * 1000));
	expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

export function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
	var c = ca[i];
	while (c.charAt(0) === ' ') c = c.substring(1,c.length);
	if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}
export function hasCompanySelectAuth(roleId) {
    if ([1,2].includes(roleId)) return true;
    return false;
}
export function checkAlpitResponse(resp) {
    if (resp.data == null) return false;
    if (resp.data.status != 'OK') return false;
    if (resp.data.data == null) return false;
    return true;
}
